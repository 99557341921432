@font-face {
  font-family: "proxima_regular";
  src: url("../assets/fonts/proxima-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/proxima-regular-webfont.woff") format("woff");
  font-display: auto;
  unicode-range: U+000-5FF;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_bold";
  src: url("../assets/fonts/proxima-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/proxima-bold-webfont.woff") format("woff");
  font-display: auto;
  unicode-range: U+000-5FF;
  font-weight: normal;
  font-style: normal;
}

$font-family-sans-serif: "proxima_regular";

.font-bolder {
  font-family: "proxima_bold";
}

.font-regular {
  font-family: "proxima_regular";
}
